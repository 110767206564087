import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"
function FindALoanOfficerPage({ data }) {
  const wpData = data.wpPage.sections.blocks
  const seo = data?.wpPage?.seo
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      <div className="loan-officer-page">
        {wpData.map(row => {
          // assign component math with name
          const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
          return <Component key={row.fieldGroupName} {...row} />
        })}
      </div>
    </Layout>
  )
}

export default FindALoanOfficerPage

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDozMDg=" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            box {
              title
              link {
                title
                target
                url
              }
            }
          }
          ... on WpPage_Sections_Blocks_Testimonials {
            fieldGroupName
            testimonials {
              ... on WpTestimonial {
                content
                id
              }
            }
            testimonialsColumn2 {
              ... on WpTestimonial {
                content
                id
              }
            }
          }
          ... on WpPage_Sections_Blocks_LoanOfficers {
            fieldGroupName
            officers {
              ... on WpLoanOfficer {
                title
                content
                slug
                uri
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                }
                officers {
                  position
                  nmls
                  hixonLendingNmls
                  licensedIn
                  location
                  phone {
                    title
                  }
                  email {
                    title
                    url
                  }
                  buttons {
                    primaryOrSecondary
                    link {
                      target
                      title
                      url
                    }
                  }
                  social {
                    link {
                      title
                      url
                    }
                    icon {
                      sourceUrl
                    }
                  }
                  slogan
                }
              }
            }
          }
        }
      }
    }
  }
`
